import { render, staticRenderFns } from "./faqForm.vue?vue&type=template&id=2c41cb6e&scoped=true&"
import script from "./faqForm.vue?vue&type=script&lang=js&"
export * from "./faqForm.vue?vue&type=script&lang=js&"
import style0 from "./faqForm.vue?vue&type=style&index=0&id=2c41cb6e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c41cb6e",
  null
  
)

export default component.exports